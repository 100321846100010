var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('section',{staticClass:"mt-5"},[_c('v-data-table',{staticClass:"elevation-1 summary-table",attrs:{"headers":_vm.tableSummary,"items":_vm.callsData,"item-key":"call_id","loading":_vm.loading,"loading-text":"Загрузка...","no-data-text":"Похоже тут ничего нет","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.client_title",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticStyle:{"cursor":"pointer"},attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push('/clients/' + item.client_id)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"#ffffff"}},[_vm._v(" mdi-account ")]),_c('span',[_vm._v(_vm._s(item.client_title))])],1)]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-1",attrs:{"color":"primary"}},[_vm._v(" mdi-clock-time-nine ")]),_c('span',[_vm._v(_vm._s(item.date))])]}},{key:"item.phone_to",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"primary"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"#ffffff"}},[_vm._v(" mdi-phone ")]),_c('span',[_vm._v(_vm._s(item.phone_to))])],1)]}},{key:"item.phone_from",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"primary"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"#ffffff"}},[_vm._v(" mdi-phone ")]),_c('span',[_vm._v(_vm._s(item.phone_from))])],1)]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-1",attrs:{"color":"primary"}},[_vm._v(" mdi-clock-time-nine ")]),_c('span',[_vm._v(_vm._s(Math.round(item.duration/1000))+" сек.")])]}},{key:"item.file_url",fn:function(ref){
var item = ref.item;
return [_c('audio',{staticClass:"vertical-middle",attrs:{"src":item.file_url,"controls":""}})]}},{key:"item.direction",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"primary"}},[(item.direction === 'OUTBOUND')?_c('v-icon',{attrs:{"color":"#ffffff"}},[_vm._v(" mdi-phone-outgoing ")]):_c('v-icon',{attrs:{"color":"#ffffff"}},[_vm._v(" mdi-phone-incoming ")])],1)]}}],null,true)}),_c('div',{staticClass:"mt-4 text-center"},[_c('v-pagination',{attrs:{"total-visible":7,"length":_vm.maxPages},on:{"input":_vm.loadData},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('h1',[_vm._v("Телефония")])])}]

export { render, staticRenderFns }