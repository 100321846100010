
















































































































































import { Vue, Component } from 'vue-class-decorator'
import { GlobalModule, CallsModule } from '@/store'

@Component({
  metaInfo: {
    title: 'Телефония'
  }
})
export default class SummaryAds extends Vue {
  public tableSummary: Array<object>
  public callsData: Array<object>
  public maxPages: number
  public page: number

  constructor() {
    super()
    this.callsData = []
    this.maxPages = 1
    this.page = 1
    this.tableSummary = [
      {
        text: 'ID',
        value: 'call_id',
        align: 'start',
        sortable: false
      },
      {
        text: 'Клиент',
        value: 'client_title',
        align: 'start',
        sortable: false
      },
      {
        text: 'Тип',
        value: 'direction',
        sortable: false
      },
      {
        text: 'От кого',
        value: 'phone_from',
        sortable: false
      },
      {
        text: 'Кому',
        value: 'phone_to',
        sortable: false
      },
      {
        text: 'Дата',
        value: 'date',
        sortable: false,
        width: '200px'
      },
      // {
      //   text: 'Время',
      //   value: 'duration',
      //   sortable: false,
      //   width: '120px'
      // },
      {
        text: 'Запись',
        value: 'file_url',
        sortable: false
      }
    ]
  }

  // Store init
  @GlobalModule.Getter('getLoading') loading!: boolean
  @CallsModule.Action('requestPaginationData') requestPaginationData!: (id: number) => Promise<any>

  loadData() {
    this.requestPaginationData(this.page)
      .then((res) => {
        this.callsData = res.calls
        this.maxPages = res.pages
      })
      .catch((error) => {
        this.$noty('error', error.message, 7000).show()
      })
  }

  created() {
    this.loadData()
  }

  // get formattedObject() {
  //   const objects = this.objectsList
  //   const newObjects = [] as any
  //   objects.forEach((object: any) => {
  //     const firstImage = JSON.parse(object.images)[0] === undefined ? '/front/images/upload/no-photo.jpg' : JSON.parse(object.images)[0].url
  //     newObjects.push({
  //       priceId: object.price_id,
  //       name: object.name,
  //       typeName: object.type_name,
  //       adress: object.adress,
  //       price: object.price,
  //       usersTitles: typeof object.users_titles === 'object' ? object.users_titles.join(', ') : object.users_titles,
  //       typeId: Number(object.type_id),
  //       advertStatus: object.sale_status === '1' ? 'Реклама: активна' : 'Реклама: на паузе',
  //       image: firstImage,
  //       saleId: object.sale_status
  //     })
  //   })
  //   return newObjects
  // }
}
